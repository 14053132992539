var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section-hero" },
    [
      _vm.isForum22
        ? _c("c-img", {
            staticClass: "hero-image",
            attrs: { src: "assets/img/theme-forum-22/new-ranks-hero.png" }
          })
        : _c("c-img", {
            staticClass: "hero-image",
            attrs: { src: "assets/img/theme/header-content-bg.jpg" }
          }),
      _c("c-img", {
        staticClass: "hero-image--mobile",
        attrs: { src: "assets/img/theme/hero-mobile.jpg" }
      }),
      _c(
        "v-container",
        { staticClass: "section-hero__container" },
        [
          _c("div", { staticClass: "section-hero__bg-content" }, [
            _c("div", { staticClass: "section-hero__bg-text" }, [
              _c("span", [
                _vm._v(" Ocean "),
                _c("br"),
                _vm._v(" of discoveries ")
              ])
            ])
          ]),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "platinum-master-inner" }, [
                  _c("div", { staticClass: "platinum-master-content" }, [
                    _c(
                      "div",
                      { staticClass: "platinum-master-tr-text-block" },
                      [
                        _c("div", { staticClass: "platinum-master-tr-text" }, [
                          _vm._v(" NEW RANKS NEW RANKS ")
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }