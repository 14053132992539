import Vuex from 'vuex'
import * as contentAPI from '@/services/api/translatesAndContent'
import { cloneDeep } from 'lodash'

const defaultState = () => ({
  nomineesByStatus: {},
  countries: [],
  cities: [],
  names: [],
  filters: {
    rank: null,
    country: null,
    city: null,
    name: null
  },
  byStatus: []
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',

  state: defaultState(),

  getters: {
    getMastersList: state => status => {
      const masters = state.nomineesByStatus[status]
      const filters = cloneDeep(state.filters)
      filters.rank && delete filters.rank

      function findByFilterParams (masters, filters) {
        return masters?.filter(master => {
          return Object.keys(filters)
            .every(propertyName => {
              if (!filters[propertyName]) return true

              return master[propertyName] === filters[propertyName]
            })
        })
      }

      return filters ? findByFilterParams(masters, filters) : masters
    },

    titles: state => Object.keys(state.nomineesByStatus) ?? []
  },

  actions: {
    async getMasters ({ commit }) {
      const { data } = await contentAPI.getNomineesGroupedByStatus()
      commit('updateMasters', data)
      commit('setFilterParams', data)
    }
  },

  mutations: {
    updateMasters (state, payload) {
      state.nomineesByStatus = Object.fromEntries(
        Object.entries(payload)
          .map(([key, val]) => [key.toLowerCase(), val])
      )
    },

    setFilterParams (state, payload) {
      const countries = new Set()
      const cities = new Set()
      const names = []

      Object.entries(payload)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .forEach(([rank, nominees]) => {
          nominees.forEach(sub => {
            countries.add(sub.country)
            cities.add(sub.city)
            names.push(sub.name)
          })
        })

      state.countries = Array.from(countries).sort()
      state.cities = Array.from(cities).sort()
      state.names = names.sort()
    },

    setFilters (state, payload) {
      state.filters = payload
    }
  }
})
