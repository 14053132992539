var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rank-wrapper" }, [
    _c(
      "div",
      { staticClass: "rank-wrapper-inner" },
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("div", { staticClass: "rank-wrapper-content" }, [
                    _c("div", { staticClass: "rank-icon" }, [
                      _c("div", { staticClass: "rank-icon__icon" }),
                      _c("div", { staticClass: "rank-icon__text" }, [
                        _vm._v(" " + _vm._s(_vm.$t(_vm.crownText)) + " ")
                      ])
                    ]),
                    _c(
                      "h3",
                      {
                        staticClass: "rank-wrapper-title",
                        attrs: { id: _vm.anchorTitle }
                      },
                      [
                        _vm.title === "platinum executive"
                          ? [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("upperFirst")(
                                      _vm.$tc(_vm.title.toLowerCase())
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          : [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("upperFirst")(
                                      _vm.$t((_vm.title + "s").toLowerCase())
                                    )
                                  ) +
                                  " "
                              )
                            ]
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "rank-wrapper-block" },
                      [
                        _c(
                          "div",
                          { staticClass: "rank-wrapper-tr-text-block" },
                          [
                            _c("div", { staticClass: "rank-wrapper-tr-text" }, [
                              _vm._v(" " + _vm._s(_vm.backgroundTitle) + " ")
                            ])
                          ]
                        ),
                        _vm._t("default")
                      ],
                      2
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }