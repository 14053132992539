var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-nominations" },
    [
      _c("Hero", { attrs: { "is-forum22": _vm.isForum22 } }),
      _c("RanksFilters", {
        ref: "ranksFilters",
        attrs: {
          ranks: _vm.titles,
          countries: _vm.countries,
          cities: _vm.cities,
          names: _vm.names,
          filters: _vm.filtersInput
        },
        on: {
          "update:filters": function($event) {
            _vm.filtersInput = $event
          }
        }
      }),
      _vm._l(_vm.modifiersMap, function(value, key) {
        return [
          key === "platinum executive"
            ? _c(
                "RankWrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow(key),
                      expression: "isShow(key)"
                    }
                  ],
                  key: key,
                  class: value,
                  attrs: { title: key }
                },
                [
                  _c("SpeakerSlider", {
                    attrs: { items: _vm.getMastersList("platinum executive") }
                  })
                ],
                1
              )
            : _c(
                "RankWrapper",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShow(key),
                      expression: "isShow(key)"
                    }
                  ],
                  key: key,
                  class: value,
                  attrs: { title: key }
                },
                [
                  _c(
                    "div",
                    { staticClass: "speaker-list" },
                    _vm._l(_vm.getMastersList(key), function(master, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "speaker-list-item" },
                        [
                          _c(
                            "v-lazy",
                            {
                              attrs: {
                                options: {
                                  threshold: 0.5
                                },
                                transition: "fade-transition"
                              }
                            },
                            [_c("Card", { attrs: { master: master } })],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }