var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "speaker-slider-block" },
      [
        _c(
          "Slick",
          {
            ref: "carousel",
            staticClass: "speaker-slider slider",
            attrs: { options: _vm.sliderSettings }
          },
          _vm._l(_vm.items, function(item, idx) {
            return _c(
              "div",
              {
                key: "" + idx + item.name,
                staticClass: "speaker-slider slide"
              },
              [_c("Card", { attrs: { master: item } })],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }