<template>
  <div class="section-hero">
    <c-img
      v-if="isForum22"
      src="assets/img/theme-forum-22/new-ranks-hero.png"
      class="hero-image"
    />
    <c-img
      v-else
      src="assets/img/theme/header-content-bg.jpg"
      class="hero-image"
    />
    <c-img
      src="assets/img/theme/hero-mobile.jpg"
      class="hero-image--mobile"
    />
    <v-container class="section-hero__container">
      <div class="section-hero__bg-content">
        <div class="section-hero__bg-text">
          <span>
            Ocean <br>
            of discoveries
          </span>
        </div>
        <!-- <div class="section-hero__bg-slogan">
          Tagline of discoveries
        </div> -->
      </div>
      <v-row>
        <v-col cols="12">
          <div class="platinum-master-inner">
            <div class="platinum-master-content">
              <div class="platinum-master-tr-text-block">
                <div class="platinum-master-tr-text">
                  <!-- Элемент дизайна, не требует перевода-->
                  NEW RANKS NEW RANKS
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    isForum22: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable max-nesting-depth */
.section-hero {
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    background-image: linear-gradient(173.93deg, var(--main-primary-background-color) 40.14%, transparent 100.29%);
    opacity: 0.6;
  }

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include below(map_get($grid-breakpoints, sm)) {
      display: none;
    }

    &--mobile {
      display: none;

      @include below(map_get($grid-breakpoints, sm)) {
        display: block;
        width: 100%;
        height: 512px;
        object-fit: unset;
      }
    }
  }
}

.section-hero__container {
  height: 100%;
  position: relative;
}

.platinum-master-tr-text-block {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
}

.platinum-master-tr-text {
  line-height: 1;
  font-family: "BebasNeuePro", sans-serif;
  font-weight: bold;
  font-style: italic;
  white-space: nowrap;
  font-size: 300px;
  text-transform: uppercase;
  color: #fff;
  -webkit-text-stroke: 1px rgba(#fff, 0.3);
  -webkit-text-fill-color: transparent;
}

.platinum-master-block {
  position: relative;
  z-index: 2;

  &__left {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__img {
    position: relative;
    right: -30px;

    img {
      max-width: 100%;
      display: block;
    }
  }

  &__right {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 2;
    padding-top: 400px;
  }

  &__content {
    padding-bottom: 100px;
  }

  &__name {
    font-family: "BebasNeuePro", sans-serif;
    text-transform: uppercase;
    color: #fff;
    font-style: italic;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 20px;
    line-height: 1.05;
    border-bottom: 1px solid rgba(#fff, 0.3);
  }

  &__time {
    font-size: 16px;
    line-height: 1.37;
    color: #fff;
    opacity: 0.6;
    margin-top: 10px;
  }

  &__slogan {
    max-width: 460px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 26px;
    line-height: 1.4;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0.9;
  }
}

@media (max-width: 1480px) {
  .platinum-master-tr-text {
    font-size: 240px;
  }

  .platinum-master-block {
    z-index: auto;
  }

  .platinum-master-block__left {
    justify-content: center;
    position: relative;
  }

  .platinum-master-block__right {
    padding-top: 0;
    position: relative;
    z-index: 4;
  }

  .platinum-master-block__img {
    right: -5%;
    height: 100vh;
  }

  .platinum-master-block__content {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 460px;
  }
}

@media (max-width: 1264px) {
  .section-hero {
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-image: linear-gradient(0deg, #202020 0%, rgba(29, 29, 29, 0) 60.87%);
      height: 500px;
      z-index: 3;
    }
  }

  .platinum-master-tr-text {
    font-size: 190px;
  }

  .platinum-master-block__img {
    max-width: 630px;
  }
}

@media (max-width: 600px) {
  .section-hero {
    border-top-right-radius: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: unset;
    max-height: 512px;
  }

  .platinum-master-block__img {
    right: auto;
    height: 590px;
    margin-left: -12px;
    margin-right: -12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .platinum-master-block__content {
    width: 100%;
    left: 0;
    transform: none;
  }

  .platinum-master-block__name {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .platinum-master-block__slogan {
    margin-top: 20px;
    font-size: 16px;
  }
}

.section-hero__bg-content {
  position: absolute;
  bottom: 50px;
  left: 12px;
  right: 12px;
  padding-bottom: 30px;

  @media screen and (max-width: 768px) {
    bottom: 15px;
  }
}

.section-hero__bg-text {
  display: none;
  color: #fff;
  font-family: var(--main-primary-font);
  font-size: 160px;
  font-weight: bold;
  text-align: right;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;

  @media screen and (max-width: 1400px) {
    font-size: 100px;
  }

  @media screen and (max-width: 860px) {
    font-size: 80px;
  }

  @media screen and (max-width: 700px) {
    font-size: 50px;
  }

  @media screen and (max-width: 475px) {
    font-size: 34px;
  }
}

// .section-hero__bg-slogan {
//   display: none;
//   color: #fff;
//   font-family: var(--main-primary-font);
//   font-size: 72px;
//   font-weight: 800;
//   line-height: 1;
//   text-transform: uppercase;
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   user-select: none;

//   @media screen and (max-width: 1400px) {
//     font-size: 60px;
//   }

//   @media screen and (max-width: 860px) {
//     font-size: 50px;
//   }

//   @media screen and (max-width: 700px) {
//     font-size: 30px;
//   }

//   @media screen and (max-width: 576px) {
//     font-size: 24px;
//   }

//   @media screen and (max-width: 475px) {
//     font-size: 20px;
//   }
// }
</style>
