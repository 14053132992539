var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "speaker-wrapper" }, [
    _c("div", { staticClass: "speaker-item" }, [
      _c(
        "div",
        { staticClass: "speaker-item__img-block" },
        [
          _c(
            "router-link",
            {
              staticClass: "speaker-item__img",
              attrs: { to: "/profile-nominee/" + _vm.master.slug }
            },
            [
              _c("Avatar", {
                attrs: {
                  src:
                    _vm.master.photo &&
                    _vm.getLinkByFileName("ranks", _vm.master.photo),
                  tile: ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "speaker-item__content" }, [
        _c(
          "div",
          { staticClass: "speaker-item__name" },
          [
            _c(
              "router-link",
              { attrs: { to: "/profile-nominee/" + _vm.master.slug } },
              [_vm._v(" " + _vm._s(_vm.master.name) + " ")]
            )
          ],
          1
        ),
        _vm.master
          ? _c("div", { staticClass: "speaker-item__location" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.master.country) +
                  ", " +
                  _vm._s(_vm.master.city) +
                  " "
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }