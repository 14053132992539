<template>
  <div class="rank_filter_wrapper">
    <v-row align="center">
      <v-col
        class="d-flex"
        lg="2"
        md="6"
        sm="12"
      >
        <v-select
          v-model="rankInput"
          :items="ranksMap"
          :label="$t('filter by rank')"
          class="rank_filter_inputs"
          clearable
          solo
        />
      </v-col>

      <v-col
        class="d-flex"
        lg="2"
        md="6"
        sm="12"
      >
        <v-select
          v-model="countryInput"
          :items="countries"
          :label="$t('filter by country')"
          class="rank_filter_inputs"
          clearable
          solo
        />
      </v-col>

      <v-col
        class="d-flex rank-filter-city-column"
        lg="4"
        md="6"
        sm="12"
      >
        <v-autocomplete
          v-model="cityInput"
          :filter="searchFilter"
          :items="cities"
          :label="$t('filter by city')"
          append-icon="mdi-magnify"
          class="rank_filter_inputs"
          clearable
          solo
        />
      </v-col>

      <v-col
        class="d-flex"
        lg="4"
        md="6"
        sm="12"
      >
        <v-autocomplete
          v-model="nameInput"
          :filter="searchFilter"
          :items="names"
          :label="$t('filter by name')"
          append-icon="mdi-magnify"
          class="rank_filter_inputs"
          clearable
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { upperFirst } from 'lodash'

export default {
  props: {
    ranks: {
      type: Array,
      default: () => ([])
    },
    countries: {
      type: Array,
      default: () => ([])
    },
    cities: {
      type: Array,
      default: () => ([])
    },
    names: {
      type: Array,
      default: () => ([])
    },
    filters: {
      type: Object,
      default: () => ({
        rank: null,
        country: null,
        city: null,
        name: null
      })
    }
  },

  data () {
    return {
      defaultRanks: [
        'platinum executive',
        'gold executive',
        'double silver executive',
        'silver executive',
        '20k executive',
        'executive',
        'diamond director',
        'emerald director',
        'gold director'
      ]
    }
  },

  computed: {
    ranksMap () {
      // return this.ranks.map(el => {
      return this.defaultRanks.map(el => {
        return ({
          text: upperFirst(this.$tc(el)),
          value: el
        })
      })
    },

    rankInput: {
      get () {
        return this.filters.rank
      },
      set (val) {
        this.$emit('update:filters', {
          ...this.filters,
          rank: val
        })
      }
    },

    countryInput: {
      get () {
        return this.filters.country
      },
      set (val) {
        this.$emit('update:filters', {
          ...this.filters,
          country: val
        })
      }
    },

    cityInput: {
      get () {
        return this.filters.city
      },
      set (val) {
        this.$emit('update:filters', {
          ...this.filters,
          city: val
        })
      }
    },

    nameInput: {
      get () {
        return this.filters.name
      },
      set (val) {
        this.$emit('update:filters', {
          ...this.filters,
          name: val
        })
      }
    }
  },

  methods: {
    searchFilter (autocomplete, queryText) {
      const options = autocomplete.toLowerCase()
      const searchText = queryText.toLowerCase()

      return options.indexOf(searchText) > -1
    }
  }
}
</script>

<style lang="scss" scoped>
  .rank_filter_wrapper {
    background-color: rgba(37, 37, 37, 0.05);
    padding: 2% 7%;

    @media screen and (max-width: 700px) {
      padding: 0 5px;

      .col {
        min-width: 210px;
      }
    }
  }

  .rank_filter_inputs {
    height: 48px;
    border-radius: 15px;
  }
</style>
